export const useUser = () => {
  const api = useApi();

  function auth(login: Login){
    return api.post<{ token: string, user: User, hasCompany: boolean, isRegisterService: boolean }>("/auth", login);
  }

  function dispatch(user: CreateUser){
    if(user.cnpj){
      return api.post<{
        token: string;
        user: User;
      }>("/user", user);
    }

    return api.post<{
      token: string;
      user: User;
    }>("/user-company", user);
  }

  return { auth, dispatch };
};